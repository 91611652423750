@import '../../../constants.scss';

$iconSize: 18px;
$iconNudge: 4px;

.inq-radiobutton {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-bottom: $standardPaddingPink;

  input {
    opacity: 0;
    position: absolute;
    pointer-events: none;
  }
  label {
    @extend .std_control_text;
    display: flex;
    flex-direction: row;
  }
  .radio-icon {
    position: relative;
    margin: 0;
    padding: 0;
    margin-right: $standardPaddingOrange;

    svg {
      width: $iconSize;
      height: $iconSize;
      top: $iconNudge;
      fill: $blue_med;
      transition: transform 150ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    }

    .svg-import {
      position: relative;
      top: $iconNudge;
      &:nth-child(2) {
        position: absolute;
        left: 0;
      }
    }

    &:not(.checked) .svg-import:nth-child(2) svg {
      transform: scale(0);
    }
  }
  .inq-label {
    flex-basis: 0;
    flex-grow: 1;
  }
}
