@import '../../../../constants.scss';

.frosted-glass-panel {
  display: block;
  position: relative;
  backdrop-filter: blur(10px);
  border-radius: $standardBorderRadiusPink;
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
}

.frosted-glass-panel-blue {
  display: block;
  position: relative;
  backdrop-filter: blur(10px);
  border-radius: $standardBorderRadiusPink;
  background-color: rgba(38, 67, 110, 0.7);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
}
