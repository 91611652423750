@import '../../../constants.scss';

.countdown-timer {
  #timerbox {
    display: inline-flex;
    width: 225px;
    height: 90px;
    background-color: white;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 5px;

    &.small {
      width: auto;
      height: auto;
      padding: 10px;
      background-color: white;
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }

  .seven-segment-panel {
    display: flex;
    align-items: center;
  }
}

.blue-timer {
  display: flex;

  .seven-segment-display {
    &.a #a,
    &.b #b,
    &.c #c,
    &.d #d,
    &.e #e,
    &.f #f,
    &.g #g {
      fill: $blue_bright;
    }
  }

  .seven-segment-dotdot {
    #dotdot {
      fill: $blue_bright;
    }
  }
}

.orange-timer {
  display: flex;

  .seven-segment-display {
    &.a #a,
    &.b #b,
    &.c #c,
    &.d #d,
    &.e #e,
    &.f #f,
    &.g #g {
      fill: $orange;
    }
  }

  .seven-segment-dotdot {
    #dotdot {
      fill: $orange;
    }
  }
}

.red-timer {
  display: flex;

  .seven-segment-display {
    &.a #a,
    &.b #b,
    &.c #c,
    &.d #d,
    &.e #e,
    &.f #f,
    &.g #g {
      fill: $red;
    }
  }

  .seven-segment-dotdot {
    #dotdot {
      fill: $red;
    }
  }
}
