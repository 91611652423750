@import '../../../constants.scss';

$composerHeight: 60px;

.chat-composer {
  $borderNudge: 0.5px;

  height: fit-content;
  min-height: $composerHeight;
  display: flex;
  align-items: center;
  justify-items: center;

  background-color: $grey_light;
  border-bottom-left-radius: $standardBorderRadiusPink - $borderNudge;
  border-bottom-right-radius: $standardBorderRadiusPink - $borderNudge;
  padding: $standardPaddingYellow;

  .composer-inner {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    background-color: $white;
    border-radius: $standardBorderRadiusPink;
    padding: $standardPaddingYellow;
    box-shadow: inset 0px 2px 4px 0px rgba(57, 57, 57, 0.5);

    textarea {
      flex-grow: 1;
      border: 0px solid transparent;
      outline-width: 0;
      border-radius: $standardBorderRadiusPink;
      padding-left: $standardPaddingPink;
      overflow-y: auto;
      resize: none;

      &:active,
      &:focus {
        border: 0px solid transparent;
        outline-width: 0;
      }
    }
  }
}
