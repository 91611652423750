@import '../../../constants.scss';

.text-description {
  display: block;
  margin-bottom: $standardPaddingPink;

  @extend .std_body_content;
}

.text-description-center {
  display: block;
  margin-bottom: $standardPaddingPink;
  text-align: center;
  @extend .std_body_content;
}
