@import '../../constants.scss';

.screen-summary {
  .frosted-glass-panel {
    .image-box {
      width: 40px;
      height: 40px;
    }
  }
  .multiline_display {
    white-space: pre-wrap;
  }
  .smallButton {
    background-color: white;
    padding-top: 0%;
    padding-bottom: 0%;
    border: 1px solid rgb(38, 67, 110);
    font-size: 14px;
  }

  .summary-fields {
    display: block;
    position: relative;

    .edit-button {
      display: block;
      text-align: center;
    }
    .healthDocument {
      .field-label {
        align-self: center;
      }
    }
  }

  .radio-title {
    .text-description {
      font-style: italic;
      margin-bottom: 1px;
    }
  }
  .inq-radiobutton {
    padding-left: 5%;
  }
  .inq-label {
    font-size: 14px;
  }
  #retakePhoto {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .MuiFormLabel-root {
    font-size: 14px;
  }
}
