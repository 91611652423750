@import '../../../constants.scss';

.chat-log {
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .scroll-magnet {
    display: block;
    margin-top: 20px;
  }
}
