.visit-timer {
  .MuiButton-contained {
    background-color: white;
    color: #26436e;
    padding: 9px;
    padding-right: 15px;
    padding-left: 15px;
    font-size: 16px;
  }
  .text-hint {
    padding-top: 32px;
    color: white;
  }
  .countdownTimerSpinnerPaper {
    margin-top: 5px;
    margin-bottom: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 225px;
    height: 90px;
  }
}
.visit-timer-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  color: white;
}
