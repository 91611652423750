.wrapper {
  margin: auto;
  margin-bottom: 50px;
  width: 250px;
  overflow: hidden;
}

#block1 {
  float: left;
}

#block2 {
  float: right;
}
