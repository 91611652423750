@import '../../constants.scss';

.provide-info {
  .formLayout {
    flex: 1;
    display: flex;
  }
  .radio-title {
    text-description {
      font-style: italic;
      margin-bottom: 1px;
    }
  }
  .inq-radiobutton {
    padding-left: 5%;
  }
  .inq-label {
    font-size: 14px;
  }
  .custom-label {
    font-family: 'Open Sans', sans-serif;
    font-style: italic;
    font-size: 14px;
    color: $blue_med;
    margin-bottom: 0px;
  }
}
