@import '../../../../constants.scss';

$logoHeight: 40px;

.logo {
  width: 100%;
  max-width: 77px;
}

.inQLogo {
  width: 32px;
}

.container {
  display: flex !important;
  height: 100%;
}

.single-panel-layout {
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.9) 0,
      rgba(255, 255, 255, 0.9) ($logoHeight + ($standardPaddingPink * 2)),
      rgba(255, 255, 255, 0) 200px + ($logoHeight + ($standardPaddingPink * 2))
    ),
    linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.7) 0,
      rgba(255, 255, 255, 0.7) ($logoHeight + ($standardPaddingPink * 2)),
      rgba(255, 255, 255, 0) 200px + ($logoHeight + ($standardPaddingPink * 2))
    );
}
