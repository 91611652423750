@import '../../../constants.scss';

$messageOffset: 80px;
.chat-message {
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  .message-bubble {
    padding: $standardPaddingYellow;
    flex-grow: 1;
    border: 1px solid $blue_ultralight;
    border-radius: $standardBorderRadiusOrange;
    padding-left: $standardPaddingOrange;
    box-shadow: 0px 2px 4px 0px rgba(57, 57, 57, 0.5);
    overflow-wrap: anywhere;
    max-width: 100%;
  }

  .chat-avatar {
    position: relative;
    top: 10px;
    box-shadow: 0px 2px 4px 0px rgba(57, 57, 57, 0.5);
  }

  @extend .std_control_text;

  margin: $standardPaddingYellow;

  &.sent {
    flex-direction: row-reverse;
    margin-left: $messageOffset;
    .message-bubble {
      border-bottom-right-radius: 0;
      background-color: $white;
    }
    .chat-avatar {
      margin-left: $standardPaddingYellow;
    }
  }
  &.received {
    margin-right: $messageOffset;
    .message-bubble {
      border-bottom-left-radius: 0;
      background-color: $blue_ultralight;
    }
    .chat-avatar {
      margin-right: $standardPaddingYellow;
    }
  }
}
