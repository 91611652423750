@import "../../constants.scss";

.health-card-form {
  label,
  p {
    font-family: "Open Sans", sans-serif;
    font-style: italic;
    font-size: 14px;
    color: $blue_med;
    margin-bottom: 16px;
  }

  .MuiFormLabel-asterisk {
    color: $red;
  }

  .MuiFormControl-root {
    display: block;
  }
}
