@import "../../../constants.scss";

.screen-check-in {
  .formLayout {
    flex: 1;
    display: flex;
  }

  label,
  p {
    font-family: "Open Sans", sans-serif;
    font-style: italic;
    font-size: 14px;
    color: $blue_med;
    margin-bottom: 0px;
  }

  .description {
    margin-bottom: 16px;
  }

  .MuiFormLabel-asterisk {
    color: $red;
  }

  .MuiFormControl-root {
    display: block;
  }
}
