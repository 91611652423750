@import '../../../constants.scss';

.inq-side-by-side-field {
  display: flex;
  flex-direction: row;
  margin-bottom: $standardPaddingGreen;
  align-items: baseline;

  .field-label {
    @extend .std_control_label1;
    display: block;
    width: 80px;
    min-width: 80px;
  }
  .field-value {
    @extend .std_control_text;
    @include formFieldoverrides();
    display: block;
    flex-grow: 1;
    margin-left: $standardPaddingGreen;
    overflow: hidden;
    text-overflow: ellipsis;

    button {
      width: 100%;
    }

    .MuiTextField-root {
      position: relative;
      top: -2px;
    }
  }
}
