@import '../../constants.scss';

.screen-please-wait {
  .frosted-glass-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 300px;
    padding-top: 16px;

    .MuiCircularProgress-root {
      position: absolute;
      top: 50%;
      margin-top: -20px;
    }
  }
}
