$spacingForCenterText: 10%;
.thank-you-for-waiting {
  .panel-footer {
    position: relative !important;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    .text-hint-center {
      padding-left: $spacingForCenterText;
      padding-right: $spacingForCenterText;
    }
  }
  .smallButton {
    background-color: white;
    border: 1px solid rgb(38, 67, 110);
  }
  .install-zoom {
    padding-bottom: 15px;
    align-items: center;
    justify-content: center;
    align-content: center;
    display: flex;
  }
}
