@import '../../constants.scss';
.meeting-screen {
  .zoom-iframe {
    border-width: 0px;
    width: 100%;
    height: 100%;
  }
  .meeting-in-progress {
    .panel-footer {
      .text-description {
        text-align: left;
      }
      .MuiButton-contained {
        background-color: white;
        color: #26436e;
        padding: 9px;
        padding-right: 15px;
        padding-left: 15px;
        font-size: 14px;
      }
    }
  }
}
