@import '../../../constants.scss';

.inq-field {
  display: flex;
  flex-direction: column;
  margin-bottom: $standardPaddingPink;
  align-items: stretch;

  .field-label {
    @extend .std_control_label1;
    display: block;
  }
  .field-value {
    @extend .std_control_text;
    @include formFieldoverrides();
    display: block;
    flex-grow: 1;

    button {
      width: 100%;
    }
    .MuiOutlinedInput-inputMultiline {
      font-size: 16px;
    }
    .MuiTextField-root {
      input {
        font-size: 16px;
      }
    }

    .MuiFormControl-root {
      margin: 0;
    }

    .MuiTextField-root {
      position: relative;
    }
  }
  .field-hint {
    @extend .std_control_hint_text;
    display: block;
  }
}
