@import '../../../constants.scss';

.text-hint {
  display: block;
  margin-bottom: $standardPaddingPink;

  @extend .std_body_italic;
}

.text-hint-center {
  display: block;
  margin-bottom: $standardPaddingPink;
  text-align: center;

  @extend .std_body_italic;
}
