@import '../../../constants.scss';

.seven-segment-panel {
  display: flex;
  justify-content: center;
}

.seven-segment-dotdot {
  display: inline-block;
  transform: skewX(-5deg);
  min-width: 6px;
  min-height: 20px;

  width: 20px;

  &.small {
    width: 10px;
    height: 30px;
  }

  #dotdot {
    fill: $blue-med;
  }
}
