.styled-file-input {
  display: block;

  input {
    position: absolute;
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
  }
}
