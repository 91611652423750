@import './constants.scss';

html {
  height: 100%;
  display: flex;
}

body {
  flex: 1;
  display: flex;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-image: url('./Assets/inQ_BackgroundImage_hiRez_40p.jpg');
  @supports (backdrop-filter: blur(10px)) {
    background-image: url('./Assets/inQ_BackgroundImage.jpg');
    @media (min-width: 188px) {
      background-image: url('./Assets/inQ_BackgroundImage_hiRez_40p.jpg');
    }
  }
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  #root {
    flex: 1;
    display: flex;
  }
  &.unsupported #root {
    display: none;
  }
  #zmmtg-root {
    display: none;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a.MuiButton-root,
button.MuiButton-root {
  text-transform: none;
}

.MuiButton-containedPrimary span {
  color: $white;
}
